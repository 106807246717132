import React from 'react'
import { Spinner } from 'react-bootstrap'
import PropTypes from 'prop-types'

import { LogoComponent } from './LogoComponent'

export const LoadingScreenComponent = ({ alwaysGrenpayLogo }) => {
    return (
        <div className="center-full-screen">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}
            >
                <LogoComponent alwaysGrenpayLogo={alwaysGrenpayLogo} />
                <Spinner
                    style={{ color: '#03ddd0' }}
                    data-testid="loading_spinner"
                />
            </div>
        </div>
    )
}

LoadingScreenComponent.propTypes = {
    alwaysGrenpayLogo: PropTypes.bool
}
