/* eslint-disable no-undef */
import aesjs from 'aes-js'
import forge from 'node-forge'
import JSencrypt from 'jsencrypt'

const { pki } = forge

const rsa_ = new JSencrypt()

export const generateAESPairs = () => {
    let key = []
    let iv
    for (let k = 0; k < 16; k++) {
        key.push(Math.floor(Math.random() * 255))
    }
    for (let k = 0; k < 16; k++) {
        iv = Math.floor(Math.random() * 255)
    }

    return {
        k: key,
        s: iv
    }
}

export const encryptV2 = async (obj, session) => {
    try {
        // INFORMACION PROTEGIDA
        const message = typeof obj !== 'string' ? JSON.stringify(obj) : obj

        const encryptedData = Buffer.from(message).toString('base64')

        return { session, ed: encryptedData }
    } catch (e) {
        console.error(`Failed to encrypt: ${e.message}`)
    }
}

export const pack = (obj, session, pair_) => {
    let pair = pair_ !== undefined ? pair_ : generateAESPairs()

    let textBytes = aesjs.utils.utf8.toBytes(JSON.stringify(obj))
    let aesCtr = new aesjs.ModeOfOperation.ctr(
        pair.k,
        new aesjs.Counter(pair.s)
    )
    let encryptedBytes = aesCtr.encrypt(textBytes)
    let encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes)

    return {
        session: session,
        ld: encryptedHex,
        lk: rsa_.encrypt(JSON.stringify(pair))
    }
}

export const createPublicKey = (pem) => {
    let privateKey = pki.privateKeyFromPem(pem)
    let publicKey = pki.setRsaPublicKey(privateKey.n, privateKey.e)
    return pki.publicKeyToPem(publicKey)
}

export const setPublicKey = (pk) => {
    rsa_.setPublicKey(pk)
}
