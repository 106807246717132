import React from 'react'
import intl from 'react-intl-universal'
import { decodeToken, isExpired } from 'react-jwt'

import mcSVG from './assets/svg/mc.svg'
import VisaSVG from './assets/svg/visa.svg'
import amexSVG from './assets/svg/amex.svg'
import credixSVG from './assets/svg/credix.svg'
import creditCardDefaultSVG from './assets/svg/creditcarddefault.svg'

import JS from 'creditcards-types/types/jcb'
import VISA from 'creditcards-types/types/visa'
import MC from 'creditcards-types/types/mastercard'
import DC from 'creditcards-types/types/diners-club'
import AX from 'creditcards-types/types/american-express'

export const getCardType = (cardnumber) => {
    if (VISA.test(cardnumber)) {
        return 'vs'
    }

    if (MC.test(cardnumber)) {
        return 'mc'
    }

    if (AX.test(cardnumber)) {
        return 'ax'
    }

    if (JS.test(cardnumber)) {
        return 'js'
    }

    if (DC.test(cardnumber)) {
        return 'dc'
    }

    return isCredix(cardnumber)
}

export const validateCardExpiration = ({ month, year }) => {
    const currentMonth = new Date().getMonth() + 1
    const currentYear = new Date().getFullYear().toString().slice(-2)

    let validMonth = false
    let validYear = false

    if (
        (year > currentYear && year <= year + 10) ||
        (year === currentYear && month >= currentMonth)
    ) {
        validMonth = true
        validYear = true
    }

    let validExpiration = validMonth && validYear
    let sameYear = year === currentYear ? month >= currentMonth : false

    return validExpiration || sameYear ? true : false
}

export const isCredix = (card) => {
    if (/^50228600[\d]{8}$/.test(card)) {
        return 'credix'
    }
    return 'unknown'
}

export const getCardIconClass = (brand) => {
    const classes = {
        vs: 'visa-icon',
        visa: 'visa-icon',
        js: 'default-card-icon',
        dc: 'default-card-icon',
        mc: 'master-card-icon',
        ax: 'amex-card-icon',
        credix: 'credix-card-icon'
    }

    return classes[brand] || 'default-card-icon'
}

export const cardSelectorBrandIcon = (brand) => {
    const visaHtml = (
        <div className="p-1">
            <img src={VisaSVG} width={45} alt="visa" />
        </div>
    )
    switch (brand) {
        case 'vs':
            return visaHtml
        case 'visa':
            return visaHtml
        case 'js':
            return (
                <div className="p-1">
                    <span
                        data-testid="js"
                        className="fab fa-cc-jcb fa-2x"
                    ></span>
                </div>
            )
        case 'dc':
            return (
                <div className="p-1">
                    <span
                        data-testid="dc"
                        className="fab fa-cc-diners-club fa-2x"
                    ></span>
                </div>
            )
        case 'mc':
            return (
                <div className="p-1">
                    <img src={mcSVG} width={45} alt="mastercard" />
                </div>
            )
        case 'ax':
            return (
                <div className="p-1">
                    <img src={amexSVG} width={45} alt="amex" />
                </div>
            )
        case 'credix':
            return (
                <div className="p-1">
                    <img
                        src={credixSVG}
                        width={45}
                        alt="credix"
                        className="credix"
                    />
                </div>
            )
        default:
            return (
                <div className="p-1">
                    <img
                        src={creditCardDefaultSVG}
                        width={45}
                        alt="Credit card"
                        className="default-credit-card"
                    />
                </div>
            )
    }
}

export const getValueIntl = (key, defaultValue = '') => {
    return intl?.get(key) ? intl.get(key) : defaultValue
}

export const getAmountFormat = (
    { amount_to, currency_to = 'CRC' },
    onlyAmount = false
) => {
    const amountFormatted = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        style: 'currency',
        signDisplay: 'never',
        currencyDisplay: 'code',
        currency: currency_to || 'CRC'
    }).format(amount_to)

    // amountFormatted => USD 22.40
    const [currency, amount] = (amountFormatted || '')
        .replace(/\s+/g, ' ')
        .split(' ')

    return onlyAmount ? { amount } : { currency, amount }
}

export const verifyToken = async (token) => {
    return new Promise(async function (resolve, reject) {
        try {
            const e = isExpired(token)
            const d = decodeToken(token)
            if (e) reject('Expired')
            if (!d) reject('Invalid')
            resolve(d)
        } catch (error) {
            reject(error)
        }
    })
}

export const utf8_to_b64 = (str) => {
    return window.btoa(encodeURIComponent(str))
}

export const b64_to_utf8 = (str) => {
    return decodeURIComponent(window.atob(str))
}
