import axios from 'axios'
import { CONFIG } from '../config'

export const checkout = async (
    data,
    token,
    endpoint = CONFIG.CHECKOUT_ENDPOINT,
    isZunify = false
) => {
    return new Promise((resolve, reject) => {
        let headers = {}
        if (endpoint === CONFIG.CHECKOUT_ENDPOINT) {
            headers = {
                'liszt-token': token
            }
        } else {
            headers = {
                'session-token': token
            }
        }
        axios({
            method: 'post',
            url: endpoint,
            data,
            headers,
            timeout: CONFIG.REQUEST_TIMEOUT
        })
            .then((res) => {
                if (isZunify) {
                    res.data?.code === 200
                        ? resolve(res.data)
                        : reject(new Error(res))
                } else {
                    resolve(res.data)
                }
            })
            .catch((e) => {
                reject(new Error(e.message))
            })
    })
}
