import React from 'react'
import PropTypes from 'prop-types'

import { Row, Col, Image } from 'react-bootstrap'

import logoGreenpay from './../assets/images/gpLogo.png'

export const LogoComponent = ({
    width = 200,
    logo = logoGreenpay,
    alwaysGrenpayLogo = false
}) => {
    let logoSrc = logoGreenpay
    if (logo) {
        logoSrc = logo
    } else if (alwaysGrenpayLogo) {
        logoSrc = logoGreenpay
    }

    return (
        <Row>
            <Col className="d-flex justify-content-center">
                <Image src={logoSrc} alt="Logo" width={width} />
            </Col>
        </Row>
    )
}

LogoComponent.propTypes = {
    width: PropTypes.number,
    logo: PropTypes.string,
    alwaysGrenpayLogo: PropTypes.bool
}
