import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'

import { AppRouter } from './router'
import { useAppGlobal } from './hooks/useAppGlobal'

function App() {
    const { initIntlDone } = useAppGlobal()

    return initIntlDone && <AppRouter />
}

export default App
