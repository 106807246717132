export const CONSTANTS = {
    ACCESS_TOKEN: 'GREENPAY_ACCESS_TOKEN',
    ERRORS: {
        INVALID_LINK: 'invalid_link',
        EXPIRED_LINK: 'expired_link',
        DISABLED_MERCHANT: 'disabled_merchant',
        WRONG_DATA: 'wrong_data',
        PURCHASE_NOT_FOUND: 'purchase_not_found',
        ERROR_FETCHING_DATA: 'error_fetching_data'
    },
    GLOBAL_STATE: {
        INITIAL: 'initial',
        LOADING: 'loading',
        FINISHED: 'finished'
    },
    MESSAGES: {
        PROCESSING_ORDER: 'processing_order',
        PROCESSING_ZUNIFY_ORDER: 'processing_zunify_order',
        ORDER_DONE: 'order_done',
        ORDER_FAIL: 'order_fail',
        ORDER_ZUNIFY_DONE: 'order_zunify_done',
        ORDER_ZUNIFY_FAIL: 'order_zunify_fail',
        FAIL_CONNECTION: 'fail_connection'
    }
}
