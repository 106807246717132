import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { LoadingScreenComponent } from '../components/LoadingScreenComponent'

const CheckoutScreen = React.lazy(() => import('../screens/CheckoutScreen'))
const NotFoundScreen = React.lazy(() => import('../screens/NotFoundScreen'))
const PaymentInvoiceScreen = React.lazy(() =>
    import('../screens/PaymentInvoiceScreen')
)
const CheckoutChargeLinkScreen = React.lazy(() =>
    import('../screens/CheckoutChargeLinkScreen')
)

export const AppRouter = () => {
    return (
        <BrowserRouter>
            <React.Suspense fallback={<LoadingScreenComponent />}>
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.7 }}
                    >
                        <Routes>
                            <Route
                                path="/:id"
                                element={<CheckoutScreen version="1" />}
                            />
                            <Route
                                path="/v2/:id"
                                element={<CheckoutScreen version="2" />}
                            />
                            <Route
                                path="/invoice/:shortened"
                                element={
                                    <CheckoutChargeLinkScreen version="1" />
                                }
                            />
                            <Route
                                path="/payment-invoice/:data"
                                element={<PaymentInvoiceScreen />}
                            />
                            <Route
                                path="/notFound"
                                element={<NotFoundScreen />}
                            />
                            <Route
                                path="/*"
                                element={<Navigate to="/notFound" />}
                            />
                        </Routes>
                    </motion.div>
                </AnimatePresence>
            </React.Suspense>
        </BrowserRouter>
    )
}
