export const CONFIG = {
    CONFIG_BUCKET: process.env.REACT_APP_CONFIG_BUCKET,
    FORMS_API_URL: process.env.REACT_APP_FORMS_API_URL,
    MERCHANT_URL: process.env.REACT_APP_MERCHANT_URL,
    KOUNT_MERCHANT: process.env.REACT_APP_KOUNT_MERCHANT,
    KOUNT_URL: process.env.REACT_APP_KOUNT_URL,
    CHECKOUT_ENDPOINT: process.env.REACT_APP_CHECKOUT_ENDPOINT,
    ORCHESTRATOR_ENDPOINTV2: process.env.REACT_APP_ORCHESTRATOR_ENDPOINTV2,
    DATA_COLLECTOR_URL: process.env.REACT_APP_DATA_COLLECTOR_URL,
    REQUEST_TIMEOUT: 120000,
    CSS_BUCKET: process.env.REACT_APP_CSS_BUCKET,
    ZUNIFY_ENDPOINT: process.env.REACT_APP_ZUNIFY_ENDPOINT,
    GP_MERCHANT_ID: process.env.REACT_APP_GP_MERCHANT_ID,
    GP_SECRET: process.env.REACT_APP_GP_SECRET,
    CARDINAL_COMMERCE_URL: process.env.REACT_APP_CARDINAL_COMMERCE_URL,
    CHECKOUTFORM_URL: process.env.REACT_APP_CHECKOUTFORM_URL,
    STATUS_PENDING_AUTHENTICATION_3DS: process.env.REACT_APP_STATUS_PENDING_AUTHENTICATION_3DS,
    STATUS_SUCCESSFUL_3DS: process.env.REACT_APP_STATUS_SUCCESSFUL_3DS,
    STATUS_FAILED_3DS: process.env.REACT_APP_STATUS_FAILED_3DS
}
